<template>
  <el-button
    v-if="p_diagnosisEntryStatus"
    type="success"
    class="statusBtn"
    :size="size"
    @click="switchStatus"
    >{{ completedText }}
  </el-button>
  <el-button
    v-else
    type="info"
    class="statusBtn"
    :size="size"
    @click="switchStatus"
    >{{ incompleteText }}
  </el-button>
</template>
<script>
export default {
  name: "StatusButton",
  props: {
    diagnosisEntryStatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      size: "mini",
      completedText: "已完成",
      incompleteText: "未完成",

      p_diagnosisEntryStatus: this.diagnosisEntryStatus,
    };
  },

  watch: {
    diagnosisEntryStatus(val) {
      this.p_diagnosisEntryStatus = val;
    },
    p_diagnosisEntryStatus(val) {
      this.$emit("update:diagnosisEntryStatus", val);
    },
  },

  methods: {
    switchStatus() {
      this.p_diagnosisEntryStatus = !this.p_diagnosisEntryStatus;
    },
  },
};
</script>
<style lang="stylus" scoped>
p {
    line-height: 2rem;
    margin: 1.5rem 0;
    font-size: 0.9rem;
}

.statusBtn {
    padding: 4px 6px;
}
</style>
